/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Modal, Radio, Input, Button, Tooltip, message } from "antd";
import { handleManualEntryDateValidation } from "../features/helperFunctions";
import { BsInfoLg } from "react-icons/bs";
import { amendExtendContractTypes, noChangeCheckboxContractTypes } from "../../Common/commondata/commonData";
import styles from "../../DocumentSection/styles/offerImport.module.css";
import { handleShowPicker } from "../../Common/utils/universalFuntions";

const ModifyDatesModal = ({ modalState, setModalState, handleInputSave, currentUrlId, rootDocData, source }) => {
  const [formState, setFormState] = useState({
    radioType: "date",
    inputValue: "",
  });
  const hanInputChanged = useRef(null);

  const handleCancel = () => {
    setModalState((prevState) => ({ ...prevState, showDateFieldModal: false, event: {} }));
  };

  useEffect(() => {
    const elements = document.querySelectorAll("input[type='date']");
    elements?.forEach((element) => {
      if (!element.disabled) {
        element.addEventListener("click", handleShowPicker);
      }
    });

    return () => {
      elements?.forEach((element) => {
        if (!element.disabled) {
          element.removeEventListener("click", handleShowPicker);
        }
      });
    };
  }, []);

  const isRelativeTime = modalState?.event?.target?.classList?.contains("relativeTimeInput");
  const isClosingDateField = modalState?.event?.target?.classList?.contains("closing-date-input");
  const requiredDateField = modalState?.event?.target?.classList?.contains("requiredDateInput");

  useEffect(() => {
    const isRelativeTime = modalState?.event?.target?.classList?.contains("relativeTimeInput");
    const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    const inputName = modalState.event?.target?.name;
    const currentData = localData[inputName];
    if (currentData) {
      if (!isNaN(Date.parse(currentData))) {
        setFormState({
          radioType: "date",
          inputValue: currentData,
        });
      } else if (currentData?.type) {
        setFormState({
          radioType: currentData.type,
          inputValue: currentData.value,
        });
      } else if (isRelativeTime) {
        setFormState({
          radioType: "time",
          inputValue: currentData,
        });
      }
    } else {
      setFormState({
        radioType: "date",
        inputValue: "",
      });
    }
  }, [currentUrlId, modalState.event?.target?.name]);

  const handleDataChange = ({ formattedData }) => {
    const inputName = modalState.event?.target?.name;
    let currentUrlIdData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    currentUrlIdData[inputName] = formattedData;
    let specificaData = { [inputName]: formattedData };
    localStorage.setItem(currentUrlId, JSON.stringify(currentUrlIdData));
    handleInputSave(specificaData);
    handleCancel();
  };

  const handleSave = () => {
    const { radioType, inputValue } = formState;
    const inputName = modalState.event?.target?.name;
    if (!inputName) {
      console.error("No input name found");
      return;
    }
    if (radioType === "date" && source !== "OFFER_IMPORT") {
      handleManualEntryDateValidation({ event: modalState.event, currentUrlId, handleInputSave, radioType, inputValue, handleDataChange, rootDocData });
      return;
    }

    let formattedData = {
      type: inputValue ? radioType : "text",
      value: inputValue ? inputValue : [...noChangeCheckboxContractTypes, "ET"].includes(rootDocData?.contractType) ||(source === "OFFER_IMPORT" && isClosingDateField) ? "" : "N/A",
      ...(radioType === "mec" && { calculatedDate: "" }),
    };

    if (source === "OFFER_IMPORT" && requiredDateField && !inputValue) {
      message.error("Please select a valid date.");
      return;
    }
    if (isClosingDateField) formattedData = formattedData.value;
    handleDataChange({ formattedData });
  };

  const onChangeRadio = (e) => {
    setFormState({ radioType: e.target.value, inputValue: "" });
  };

  const onChangeInput = (e) => {
    setFormState((prevState) => ({ ...prevState, inputValue: e.target.value }));
    hanInputChanged.current = true;
  };

  const inputProps = (type) => ({
    name: modalState.event?.target?.name,
    value: formState.radioType === type ? formState.inputValue : "",
    onChange: onChangeInput,
    disabled: formState.radioType !== type,
  });

  let labelText = modalState?.event?.target?.parentElement?.previousElementSibling?.textContent || (isRelativeTime ? "Select Time" : "Select Date");

  if (source === "OFFER_IMPORT") {
    const label = modalState?.event?.target?.parentElement?.querySelector(`.${styles.Customlbl}`);
    labelText = label?.textContent?.trim().replace(/^\*\s*/, "") || (isRelativeTime ? "Select Time" : "Select Date");
  }

  return (
    <Modal
      visible={true}
      width={"600px"}
      title={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "22px",
            color: "grey",
            width: "90%",
            overflow: "elipsis",
          }}
        >
          {labelText}
        </span>
      }
      onCancel={handleCancel}
      footer={null}
    >
      <div style={{ height: "fit-content", overflowY: "auto", overflowX: "hidden" }}>
        <div style={{ display: "flex", maxWidth: "400px", flexDirection: "column", gap: "20px" }}>
          <Radio.Group onChange={onChangeRadio} value={formState.radioType}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "400px" }}>
              {isRelativeTime && rootDocData?.builtForSection !== "TEMPLATE" && (
                <Radio value="time">
                  Select Time
                  <Input type="time" {...inputProps("time")} style={{ margin: "5px", width: "140px" }} />
                </Radio>
              )}
              {!isRelativeTime && rootDocData?.builtForSection !== "TEMPLATE" && (
                <Radio value="date">
                  Select Date
                  <Input type="date" {...inputProps("date")} style={{ margin: "5px", width: "140px" }} />
                </Radio>
              )}
              {!isRelativeTime && !isClosingDateField && ![...amendExtendContractTypes, "ET"].includes(rootDocData?.contractType) && (
                <Radio value="mec">
                  <Input type="number" {...inputProps("mec")} max={1000} style={{ margin: "5px", width: "70px" }} /> Days After MEC
                </Radio>
              )}
              {!isClosingDateField && (
                <Radio value="text" style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Input placeholder="Enter text" maxLength={30} {...inputProps("text")} style={{ margin: "5px", width: "230px" }} />
                    <Tooltip title={"RE-Sure is not able to build Calendar reminders if you choose this option."} placement="right">
                      <BsInfoLg style={{ fontSize: "35px" }} />
                    </Tooltip>
                  </div>
                </Radio>
              )}
            </div>
          </Radio.Group>
        </div>
        <div style={{ textAlign: "center", margin: "40px 30px 30px 30px" }}>
          <Button type="primary" onClick={handleSave} disabled={!hanInputChanged.current}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyDatesModal;
