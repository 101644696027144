import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import { convertDate, createOfferContractTypes, dateData, noChangeCheckboxContractTypes } from "../../Common/commondata/commonData";
import { features } from "./features";
import setDayOfWeek from "./helpers/setDayOfWeek";
import accounting from "accounting";
import calculatePriceTable from "./helpers/calculatePriceTable";
import { smallHelpersFun } from "./helpers/smallHeperFunctions";
import { section3Fields } from "../../Common/commondata/cbsrFields";
import { formatPhoneNumber } from "../../Common/utils/universalFuntions";
import moment from "moment";
import buildSignatureBlocksForAgents from "./helpers/buildSignatureBlocksForAgents";
import getFullNamesFromArray from "./helpers/getFullNamesFromArray";
const { confirm } = Modal;

export const validateEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};
const showConfirm = (event, currentUrlId, createOfferData, handleInputSave, specificData) => {
  if (event?.target?.checked) {
    confirm({
      title: "Are you sure to fill the dates automatically?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Your manually filled dates will be lost. Do you still want to continue?",
      onOk() {
        handleDateFillFunction(event, currentUrlId, createOfferData, handleInputSave, specificData);
      },
      onCancel() {
        let allCheckBoxex = document.querySelectorAll(`input[name="AutoFillDates"]`);
        allCheckBoxex?.forEach((el) => {
          el.checked = false;
        });
      },
    });
  }
};


export function handleToggleAutoFillDates(event = {}, currentUrlId, handleInputSave, specificData) {
  if (event.target.checked) {
    let closingDate = JSON.parse(localStorage.getItem(currentUrlId))?.ClosingDate;
    closingDate = !isNaN(Date.parse(closingDate?.value))  ? moment(closingDate?.value) : !isNaN(Date.parse(closingDate))? moment(closingDate): null;
    let originalContractDate = JSON.parse(localStorage.getItem(currentUrlId))?.OriginalContractDate || moment();
    originalContractDate = moment(originalContractDate);
    // console.log("closingDate", moment(closingDate).format("MM-DD-YYYY"),moment(originalContractDate).format("MM-DD-YYYY") )
    // Checking if the difference is less than 29 days
    if (closingDate && closingDate.diff(originalContractDate, 'days') < 29) {
      message.info("Please select a closing date at least 30 days from the document initiation date to fill Table 3.1 dates automatically.", 4);
      event.target.checked = false;
      return;
    }
    showConfirm(event, currentUrlId, handleInputSave, specificData);
  } else {
    handleDateFillFunction(event, currentUrlId, handleInputSave, specificData);
  }
}

// Offers all the dates filled automattically
export function handleDateFillFunction(event, currentUrlId, handleInputSave, specificData) {
  if (!event.target.checked) {
    smallHelpersFun.updateElements("checkbox", `input[name=${event.target.name}]`, false);
    specificData[event.target.name] = "";
    let mainLocalData = JSON.parse(localStorage.getItem(currentUrlId));
    mainLocalData[event?.target?.name] = "";
    localStorage.setItem(currentUrlId, JSON.stringify(mainLocalData));
    dateData.forEach((ele) => {
      let localData = JSON.parse(localStorage.getItem(currentUrlId));
      if (ele.canRemoved) {
        let eleValue = localData[ele.keyName];
        if (eleValue) {
          setDayOfWeek({
            name: ele.keyName,
            value: "",
          });
          smallHelpersFun.updateElements("input", `input[name=${ele.keyName}]`, "");
          if (ele.type === 'date') {
            specificData[ele.keyName] = {
              type: "text",
              value:"N/A"
            }
            localData[`${ele.keyName}`] = {
              type: "text",
              value:"N/A"
            }
          } else {
            specificData[ele.keyName] = ""
            localData[`${ele.keyName}`] = ""
          }
          localStorage.setItem(currentUrlId, JSON.stringify(localData));
        }
      }
    });
    handleInputSave(specificData);
  } else {
    smallHelpersFun.updateElements("checkbox", `input[name=${event.target.name}]`, true);
    specificData[event?.target?.name] = event?.target?.value;
    let mainLocalData = JSON.parse(localStorage.getItem(currentUrlId));
    mainLocalData[event?.target?.name] = event?.target?.value;
    localStorage.setItem(currentUrlId, JSON.stringify(mainLocalData));
    features.autoFillDateLogic(currentUrlId, "ToggleCheckbox", specificData);
    handleInputSave(specificData);
  }
}

export const ctrlShiftKey = (e, keyCode) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};

export const d = {
  el: {
    // get Element/s capital A is all elements
    id: (id) => document.getElementById(id),
    q: (selector) => document.querySelector(selector),
    qA: (selector) => document.querySelectorAll(selector),
    clA: (className) => document.getElementsByClassName(className),
    nA: (name) => document.getElementsByName(name),
    tgA: (tagName) => document.getElementsByTagName(tagName),

    // create Element
    Cr8: (tagName) => document.createElement(tagName),

    // remove Element
    r: (element) => element.remove(),

    // style an element
    s: (element, styles) => {
      for (let property in styles) {
        element.style[property] = styles[property];
      }
    },
    sM: (elements, styles) => {
      for (let i = 0; i < elements.length; i++) {
        for (let property in styles) {
          elements[i].style[property] = styles[property];
        }
      }
    },

    // attributes M is multiple attributes
    at: {
      s: (attributeName, value, element) => element.setAttribute(attributeName, value),
      g: (attributeName, element) => element.getAttribute(attributeName),
      sM: (attributes, element) => {
        for (let attribute in attributes) {
          element.setAttribute(attribute, attributes[attribute]);
        }
      },
      gM: (attributesArray, element) => {
        const attributes = {};
        for (let i = 0; i < attributesArray.length; i++) {
          attributes.push(element.getAttribute(attributesArray[i]));
        }
        return attributes;
      },
    },

    // append
    ap: (child, element) => element.append(child),

    // add eventListener
    ev: {
      a: (eventType, callback, element) => {
        element.addEventListener(eventType, callback);
      },
      r: (eventType, callback, element) => {
        element.removeEventListener(eventType, callback);
      },
    },
  },

  // localStorage
  l: {
    // setItem s -> non stringified, sS  -> stringified
    s: (itemName, item) => localStorage.setItem(itemName, item),
    sS: (itemName, item) => localStorage.setItem(itemName, JSON.stringify(item)),
    // getItem g -> non parsed, gP -> parsed
    g: (itemName) => localStorage.getItem(itemName),
    gP: (itemName) => JSON.parse(localStorage.getItem(itemName) || {}),
  },

  // consoles
  lg: function () {},
};

export function makeAllFieldsDisabled(currentUrlId, rootDocData, contractData) {
  const { openFor, contractType, RTDInitiator, signatureInfo, builtForSection, createOrEditTemplate, isOfferClone, buyerDocumentStatus, signable, buyerIds, checkForDisable, buyerAgentId, propertyId, propertyData } = rootDocData || {};

  let localData = JSON.parse(localStorage.getItem(currentUrlId));
  if (!localData) {
    return;
  }
  const inputElements = document.querySelectorAll("input, textarea");
  const buyerAddButton = document.querySelector(`[data-buyer-edit-icon]`);
  const sellerAddButton = document.querySelector(`[data-seller-edit-icon]`);
  const addDocsClausesBtns = document.querySelectorAll(`[data-add-docclause]`);
  const addTitleOfficeBtn = document.querySelectorAll(`[data-add-title-office]`);
  const coBuyerBrokerEditBtn = document.querySelectorAll(`[data-co-buyer-broker-edit-icon]`);
  const coSellerBrokerEditBtn = document.querySelectorAll(`[data-co-seller-broker-edit-icon]`);
  const coBuyerBrokerDeleteBtn = document.querySelectorAll(`[data-co-buyer-broker-delete-icon]`);
  const coSellerBrokerDeleteBtn = document.querySelectorAll(`[data-co-seller-broker-delete-icon]`);
  const addCoBuyerBrokerBtn = document.querySelectorAll(`[data-add-coBuyerBroker-btn]`);
  const addCoSellerBrokerBtn = document.querySelectorAll(`[data-add-coSellerBroker-btn]`);
  const buyerForms = document.getElementById("buyerAgentFields");
  const sellerForms = document.getElementById("sellerAgentFields");
  const buyerAgentIdFields = document.getElementById("buyerAgentIdFields");
  const sellerAgentIdFields = document.getElementById("sellerAgentIdFields");
  const signatureBlocks = document.querySelectorAll(".signature-block");
  const ListingPeriodBeginDate = document.getElementsByName("ListingPeriodBeginDate");
  const ListingPeriodEndByDate = document.getElementsByName("ListingPeriodEndByDate");
  const propertyAddress= document.getElementsByName("FullAddress");
  const buyersSignWithDrawBlock = document.querySelector(`input[name=LetBuyersSignWithDrawBlock]`);
  const lbpdBuyerEditableFields = document.getElementsByName("BuyerHasElectedTo");
  const corpSignersList = document.getElementById("corpSignersList");


  if (ListingPeriodBeginDate && ListingPeriodEndByDate && ["ERTB", "ERTS", "ERTL", "ETCT"].includes(contractType)) {
    const oneDayBefore = smallHelpersFun.adjustDate(ListingPeriodEndByDate[0]?.value, -1);
    const oneDayAfter = smallHelpersFun.adjustDate(ListingPeriodBeginDate[0]?.value, 1);
    if (oneDayBefore) {
      ListingPeriodBeginDate.forEach(function (element) {
        element.max = oneDayBefore;
      });
    }
    if (oneDayAfter) {
      ListingPeriodEndByDate.forEach(function (element) {
        element.min = oneDayAfter;
      });
    }
  }
  if (["LCAE", "LCAES", "LCAES_ERTL", "LCAES_ETCT"].includes(contractType) && ListingPeriodEndByDate) {
    let startDate = localData["ListingPeriodBeginDate"];
    const oneDayAfter = smallHelpersFun.adjustDate(startDate, 1);
    if (oneDayAfter) {
      ListingPeriodEndByDate.forEach(function (element) {
        element.min = oneDayAfter;
      });
    }
  }
  let buyersCount = 0;
  buyerIds?.forEach((el) => {
    if (el.isCorp) {
      buyersCount += el.signers?.length;
    } else {
      buyersCount += 1;
    }
  });
  const anyOneSigned = (signatureInfo || []).length > 0;
  let anyBuyerSideSigned = false,
    anySellerSideSigned = false,
    allBuyersSigned = false,
    anyBuyerAgentSigned = false,
    anySellerAgentSigned = false;
  signatureInfo?.forEach((element) => {
    if (element.roleType === "BUYER" || element.roleType === "BUYER_AGENT") {
      anyBuyerSideSigned = true;
      if (element.roleType === "BUYER_AGENT") {
        anyBuyerAgentSigned = true;
      }
    } else {
      anySellerSideSigned = true;
      if (element.roleType === "SELLER_AGENT") {
        anySellerAgentSigned = true;
      }
    }
  });
  if (buyerDocumentStatus === "SUBMITTED") {
    allBuyersSigned = true;
  }
  const conditionalDisableElements = (forms, effect) => {
    if (forms) {
      const readOnlyEffect = effect === "none" ? true : false;
      forms.querySelectorAll("input, textarea")?.forEach((element) => {
        handleSingleElementDisable({ element, readOnlyEffect, effect });
      });
      const coBuyerBrokerEditBtn = forms.querySelectorAll(`[data-co-buyer-broker-edit-icon]`);
      const coSellerBrokerEditBtn = forms.querySelectorAll(`[data-co-seller-broker-edit-icon]`);
      const coBuyerBrokerDeleteBtn = forms.querySelectorAll(`[data-co-buyer-broker-delete-icon]`);
      const coSellerBrokerDeleteBtn = forms.querySelectorAll(`[data-co-seller-broker-delete-icon]`);
      disableElements([coBuyerBrokerEditBtn, coSellerBrokerEditBtn, coBuyerBrokerDeleteBtn, coSellerBrokerDeleteBtn], effect);
    }
  };

  const handleSingleElementDisable = ({ element, readOnlyEffect, effect }) => {
    if (!element?.style) return;
    if (element.tagName === "INPUT" || element.tagName === "TEXTAREA") {
      if (element.type !== "checkbox" && element.type !== "radio" && !element.classList.contains("relativeDateInput") && !element.classList.contains("relativeTimeInput") && element.name !== "FullAddress") {
        element.readOnly = readOnlyEffect;
        // Adding or removing class based on readOnlyEffect
        element.classList.toggle("noAutoSave", readOnlyEffect);
      } else {
        element.style.pointerEvents = effect;
      }
    } else {
      element.style.pointerEvents = effect;
    }
  };
  

  const disableElements = (elements, effect) => {
    const readOnlyEffect = effect === "none" ? true : false;
    elements = Array.isArray(elements) ? elements : [elements];
    elements.forEach((element) => {
      if (element instanceof NodeList || Array.isArray(element)) {
        element.forEach((node) => {
          handleSingleElementDisable({ element: node, readOnlyEffect, effect });
        });
      } else {
        handleSingleElementDisable({ element, readOnlyEffect, effect });
      }
    });
  };

  const hideOrShowElements = (elements, effect, type) => {
    elements = Array.isArray(elements) ? elements : [elements];
    elements.forEach((element) => {
      if (element instanceof NodeList || Array.isArray(element)) {
        element.forEach((node) => {
          if (node && node.style) {
            node.style.display = effect;
          }
        });
      } else {
        if (element && element.style) {
          element.style.display = effect;
        }
      }
    });
  };

  const disableElementsByName = (arrayOfInputNames=[], effect) => {
    arrayOfInputNames.forEach((el) => {
      let inputElement = document.querySelectorAll(`[name="${el}"]`);
      disableElements(inputElement, effect);
    });
  };
  
  const disableAllElements = () => {
    disableElements(inputElements, "none");
    disableElements(addDocsClausesBtns, "none");
    disableElements(addTitleOfficeBtn, "none");
    hideOrShowElements([buyerAddButton, sellerAddButton, addCoBuyerBrokerBtn, addCoSellerBrokerBtn, coBuyerBrokerEditBtn, coSellerBrokerEditBtn, coBuyerBrokerDeleteBtn, coSellerBrokerDeleteBtn], "none");
    conditionalDisableElements(buyerForms, "none");
    conditionalDisableElements(sellerForms, "none");
  };

  if (anyOneSigned || openFor === "BUYER" || (openFor === "SELLER" && contractType !== "SPD") || (openFor === "SELLER_AGENT" && !allBuyersSigned && isOfferClone)) {
    disableAllElements();
  }

  // console.log("contractType", contractType, RTDInitiator, openFor, sellerForms);
  if (createOfferContractTypes.includes(rootDocData?.contractType)) {
    switch (openFor) {
      case "BUYER_AGENT":
        if (Array.isArray(propertyData?.propertyType) && propertyData.propertyType.includes("PRIVATE")) {
          hideOrShowElements([addCoSellerBrokerBtn, coSellerBrokerDeleteBtn], "none");
        }
        if (!anyBuyerAgentSigned) {
          conditionalDisableElements(buyerForms, "auto");
          hideOrShowElements([addCoBuyerBrokerBtn, coBuyerBrokerEditBtn, coBuyerBrokerDeleteBtn], "inline-block");
        }
        if (!anyOneSigned && !localData?.SellerAgents?.length) {
          hideOrShowElements([coSellerBrokerEditBtn, addCoSellerBrokerBtn, coSellerBrokerDeleteBtn], "inline-block");
        }
        break;

      case "SELLER_AGENT":
        disableAllElements();

        if (!anySellerSideSigned) {
          hideOrShowElements([sellerAddButton], "inline-block");
          conditionalDisableElements(corpSignersList, "auto");
        }

        if (!anySellerAgentSigned) {
          conditionalDisableElements(sellerForms, "auto");
          hideOrShowElements([coSellerBrokerEditBtn, addCoSellerBrokerBtn, coSellerBrokerDeleteBtn], "inline-block");
        }
        break;

      default:
        disableAllElements();
        break;
    }

    // Common code
    if (!buyerAgentId && buyerAgentIdFields) {
      buyerAgentIdFields.style.display = "none";
    }

    if (!localData["SellerBrokerName"] && sellerAgentIdFields) {
      sellerAgentIdFields.style.display = "none";
    }
  }

  if (!createOfferContractTypes.includes(contractType)) {
    switch (RTDInitiator) {
      case "BUYER":
        if (openFor === "BUYER_AGENT") {
          hideOrShowElements([addCoSellerBrokerBtn, coSellerBrokerDeleteBtn], "none");
        } else if (openFor === "SELLER_AGENT") {
          disableAllElements();
          if (!anySellerSideSigned) {
            conditionalDisableElements(corpSignersList, "auto");
            hideOrShowElements([sellerAddButton], "inline-block");
          }
          if (!anySellerAgentSigned) {
            conditionalDisableElements(sellerForms, "auto");
            hideOrShowElements([addCoSellerBrokerBtn, coSellerBrokerEditBtn, coSellerBrokerDeleteBtn], "inline-block");
          }
        }
        if (contractType === "COS" && sellerForms) {
          sellerForms.style.display = "none";
        }
        break;
      case "SELLER":
        if (openFor === "SELLER_AGENT") {
          if (!rootDocData?.offerId) {
            conditionalDisableElements(buyerForms, "none");
            hideOrShowElements([coBuyerBrokerEditBtn], "none");
            if (contractType !== "ERTS" && !propertyId) {
              hideOrShowElements([addCoSellerBrokerBtn, coSellerBrokerDeleteBtn], "none");
            }
          }
          hideOrShowElements([addCoBuyerBrokerBtn, coBuyerBrokerDeleteBtn], "none");
        } else if (openFor === "BUYER_AGENT") {
          disableAllElements();
          if (!anyBuyerSideSigned) {
            conditionalDisableElements(corpSignersList, "auto");
            hideOrShowElements([buyerAddButton], "inline-block");
          }
          if (!anyBuyerAgentSigned) {
            conditionalDisableElements(buyerForms, "auto");
            hideOrShowElements([addCoBuyerBrokerBtn, coBuyerBrokerEditBtn, coBuyerBrokerDeleteBtn], "inline-block");
          }
        }
        if (contractType === "COS" && buyerForms) {
          buyerForms.style.display = "none";
        }
        break;
      default:
        break;
    }

    if (buyersSignWithDrawBlock && openFor === "BUYER_AGENT" && RTDInitiator === "BUYER" && buyersCount === signatureInfo?.length) {
      buyersSignWithDrawBlock.style.pointerEvents = "auto";
    } else if (buyersSignWithDrawBlock) {
      buyersSignWithDrawBlock.style.pointerEvents = "none";
    }
    // LBPD form > Buyers Acknowledgement fields should be editable by Buying side only
    if (contractType === "LBPD" && lbpdBuyerEditableFields) {
      if ((openFor === "BUYER" || openFor === "BUYER_AGENT") && !anyBuyerSideSigned) {
        disableElements(lbpdBuyerEditableFields, "auto"); // Enable for buying side
      } else {
        disableElements(lbpdBuyerEditableFields, "none"); // Disable for selling side
      }
    }
    if (!buyerAgentId && buyerAgentIdFields) {
      buyerAgentIdFields.style.display = "none";
    }
    if (!localData["SellerBrokerName"] && sellerAgentIdFields) {
      sellerAgentIdFields.style.display = "none";
    }
  }

  if (builtForSection === "TEMPLATE") {
    if (createOrEditTemplate) {
      hideOrShowElements([buyerAddButton, addCoBuyerBrokerBtn, coBuyerBrokerEditBtn, coBuyerBrokerDeleteBtn, sellerAddButton, addCoSellerBrokerBtn, coSellerBrokerEditBtn, coSellerBrokerDeleteBtn], "none");
      disableElements(signatureBlocks, "none");
      disableElementsByName(contractData?.templateDisabledFields || [], "none");
    } else {
      disableAllElements();
    }
  }
  if (contractType === "CI" && signable) {
    let hasTitleCompanyRole = false;
    signatureInfo?.forEach((info) => {
      if (info?.roleType === "TITLE_COMPANY") {
        hasTitleCompanyRole = true;
      }
    });
    const signerTitleInputField = document.getElementById("SignerTitleField");
    if (signerTitleInputField && openFor === "TITLE_COMPANY") {
      if (hasTitleCompanyRole) {
        disableElements(signerTitleInputField, "none");
      } else {
        disableElements(signerTitleInputField, "auto");
      }
    } else if (signerTitleInputField && openFor !== "TITLE_COMPANY") {
      disableElements(signerTitleInputField, "none");
    }
  }

  if (!["ERTS"].includes(contractType)) {
    disableElementsByName(section3Fields, "none");
  }

   if (!contractData?.propertyAddressEditable || anyOneSigned) {
    disableElementsByName(propertyAddress, "none");
  }
  // whole document will be completely disabled if any of the below fields becomes true
  if (checkForDisable?.disabled || builtForSection === "READ_ONLY_DOCUMENT" || localData["invalidated"] || localData["onlyTemplateview"] === true) {
    disableAllElements();
  }

}

export function handleToggleAutoPriceCalculation(event, currentUrlId, handleInputSave, specificData) {
  const fieldsToGet = ["PurchasePrice", "EarnestMoney", "NewLoan", "AssumptionBalance", "PrivateFinancing", "SellerFinancing", "AdditionalPriceAmt1", "AdditionalPriceAmt2"];
  const tableValues = {};
  const updateValuesAndDOM = (values, action) => {
    for (const key in values) {
      specificData[key] = values[key];
      document.getElementsByName(key)?.forEach((element) => {
        element.value = values[key];
        element.parentNode.dataset.replicatedValue = values[key];
      });
    }
    const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    localStorage.setItem(currentUrlId, JSON.stringify({ ...localData, ...specificData }));
    handleInputSave(specificData);
  };

  fieldsToGet.forEach((field) => {
    let elements;
    if (event.target.checked) {
      elements = [document.querySelector(`[name="${field}"]`)];
    } else {
      elements = document.getElementsByName(field);
    }
    const value = elements?.[0]?.value ? accounting.unformat(elements[0].value) : event.target.checked ? 0.0 : 0;
    tableValues[field] = field === "PurchasePrice" ? value : 0.0;
  });
  const updatedValues = calculatePriceTable(tableValues, event.target.checked ? undefined : "CheckboxUnchecked");
  updateValuesAndDOM(updatedValues);
}

export function handleSignatureCompletion(signatureBlock) {
  // console.log("parentElement", signatureBlock);
  const { _id, sign, initials, timeStamp, roleType, key } = signatureBlock;
  let signatureDate = convertDate(timeStamp);
  let parentElement;
  if (key && _id) {
    parentElement = document.getElementById(roleType + "_" + _id + "_key_" + key);
    // console.log("parentElement", parentElement);
  } else {
    parentElement = document.getElementById(roleType + "_" + (_id || roleType));
    if(!parentElement){
      parentElement = document.getElementById(roleType + "_" + (_id || roleType) + "_key_" + key);
    }
  }

  if (parentElement) {
    const verifiedSignDiv = document.createElement("div");
    verifiedSignDiv.className = "signature-block signature-wrapper";
    verifiedSignDiv.innerHTML = `
    <div class="signature-block signature-img">
      <img src="${sign}" alt="Sign"/>
    </div>
    <div class="signature-block signature-verification">RE-Sure Verified<br /> ${initials !== undefined ? timeStamp + initials : timeStamp}</div>
  `;
    parentElement.innerHTML = "";
    parentElement.appendChild(verifiedSignDiv);
  }
  let signatureDateElement;
  if (key && _id) {
    signatureDateElement = document.querySelector(`[data-signatureDate="${roleType + "_" + _id + "_key_" + key}"]`);
  } else {
    signatureDateElement = document.querySelector(`[data-signatureDate="${roleType + "_" + (_id || roleType)}"]`);
  }
  //  console.log("signatureDateElement", signatureDateElement);
  if (signatureDateElement) {
    signatureDateElement.textContent = signatureDate || "";
  }
}

// for splitting older transactions office details at top header on offer forms
const formatOfficeDetails = (officeDetails) => {
  const nameAndAddress = officeDetails?.nameandAddress;
  if (!nameAndAddress) return "";
  const parts = nameAndAddress.split(" - ");

  // If the last part starts with a number, treat it as the address
  const lastPart = parts[parts.length - 1];
  if (/^\d/.test(lastPart)) {
    // Join all parts except the last as the company name
    const companyName = parts.slice(0, parts.length - 1).join(" - ");
    // Return company name on one line, and the address (last part) on the next line
    return `${companyName}\n${lastPart}`;
  }

  // If no valid address part found, return the original string
  return nameAndAddress;
};

export function showCompanyDetails(td, rootDocData) {
  if (!td) return;
  const agentOfficeLogo = document.querySelector("#agentOfficeLogo");
  let agentData = {};
  if (rootDocData?.contractType === "ERTS") {
    agentData = td?.SellerAgents?.[0] || {};
  } else {
    agentData = td?.BuyerAgents?.[0] || {};
  }
  const setLogoAttributes = () => {
    const { officeDetails: { officeLogo } = {} } = td || {};
    agentOfficeLogo.innerHTML = "";
    if (!officeLogo) return;
    // Seting a temporary image to calculate our image original dimensions
    const tempImage = new Image();
    tempImage.src = officeLogo;
    tempImage.onload = () => {
      const aspectRatio = tempImage.width / tempImage.height;
      const desiredWidth = 100;
      const calculatedHeight = desiredWidth / aspectRatio;
      const logoHtml = `<img src="${officeLogo}" alt="Office Logo" width="${desiredWidth}px" height="${calculatedHeight}px" />`;
      agentOfficeLogo.innerHTML = logoHtml;
    };
  };

  if (agentOfficeLogo && td?.officeDetails) {
    setLogoAttributes();
  }

  const setFieldTextByID = (fieldId, value) => {
    const field = document.querySelector(`#${fieldId}`);
    if (field) field.innerText = value || "";
  };
  setFieldTextByID("AGENT_NAME", agentData.fullName || "");
  setFieldTextByID(
    "AGENT_BROKER_ADDRESS",
    td?.officeDetails?.nameandAddress
      ? formatOfficeDetails(td.officeDetails) // Handling for old transactions
      : td?.officeDetails?.name && td?.officeDetails?.address
      ? `${td.officeDetails.name}\n${td.officeDetails.address}`
      : td?.officeDetails?.name && !td?.officeDetails?.address
      ? `${td?.officeDetails.name}`
      : !td?.officeDetails?.name && td?.officeDetails?.address
      ? `${td?.officeDetails.address}`
      : ""
  );
  setFieldTextByID("AGENT_EMAIL", agentData.email && agentData.phoneNumber ? `${agentData.email} | ${formatPhoneNumber(agentData.phoneNumber)}` : agentData.email && !agentData.phoneNumber ? `${agentData.email}` : !agentData.email && agentData.phoneNumber ? `${formatPhoneNumber(agentData.phoneNumber)}` : "");
}

export function handleDifferentKeyOfClient({ clientArray, i, source }){
  const newClients = clientArray?.map((client) => {
    let temp = {
      ...client,
    };
    if (temp?.isCorp) {
      temp.key = temp?.key + (i ? `_${i}` : "");
    } else {
      if (source === "AGENT") { 
        temp.key = (temp?.key || temp.personId) + (i ? `_${i}` : "");
      }else{
        temp.key = (temp?.personId) + (i ? `_${i}` : "");
   }
  }
    return temp;
  });
  return newClients;
};

export function renderAgentSignatureBox({rootDocData, openFor, agentType, savedValue, contractData}){
  let i = 0;
  while (true) {
    let signatureBlockName = i ? `${agentType}_${i}` : agentType;
    let signatureElement = document.querySelectorAll(`[data-signature-block="${signatureBlockName}"]`);
    if (!signatureElement?.length) {
      break;
    }
    const currentBuyerClient = handleDifferentKeyOfClient({ clientArray: savedValue, i, source:"AGENT" });
    const sigBlock = buildSignatureBlocksForAgents({
      signers: currentBuyerClient,
      type: agentType,
      rootDocData: { ...rootDocData, personKey: `${rootDocData?.builtForId}${i ? `_${i}` : ""}`, key: `${rootDocData?.key}${i ? `_${i}` : ""}` },
      contractData,
      requiredOpenFor: openFor
    });
    signatureElement.forEach((element) => {
      element.innerHTML = sigBlock;
    });
    i++;
  }
}

let currentConfirmationModal = null;
const showManualEntryDateConfirmation = ({  messageTitle = "", isClosingDateField, inputValue, handleDataChange }) => {
  if (currentConfirmationModal) {
    currentConfirmationModal.destroy();
    currentConfirmationModal = null;
  }
  currentConfirmationModal = confirm({
    title: messageTitle,
    okText: "Yes",
    cancelText: "No",
    icon: <ExclamationCircleOutlined />,
    content: "Do you still want to proceed?",
    onOk() {
      let formattedData = {
        type: inputValue ? "date" : "text",
        value: inputValue || "N/A",
      };
      if (isClosingDateField) formattedData = formattedData.value;
      handleDataChange({formattedData})
    },
    onCancel() {
    },
  });
};



export function handleManualEntryDateValidation({ event = {},  inputValue, handleDataChange, rootDocData }) {
  try {
    const isClosingDateField = event?.target?.classList?.contains("closing-date-input");
    const parseDateToTimestamp = (dateString) => moment(dateString).valueOf();

    const getTextBeforeInput = (inputElement) => {
      const previousElement = inputElement?.parentElement?.previousElementSibling;
      return previousElement ? previousElement.textContent?.trim() : "";
    };
     // Checking for ClosingDate validation
    if (event.target.hasAttribute("data-checkDateValidation")) {
      const closingDateElement = document.querySelector(`[name="${event.target?.getAttribute("data-checkDateValidation")}"]`);
      // console.log("nextElement.value", closingDateElement.value, nextElement.value);
      if (closingDateElement && parseDateToTimestamp(closingDateElement.value) < parseDateToTimestamp(inputValue)) {
        const fieldName = getTextBeforeInput(closingDateElement);
        let messageTitle = `The selected date should not be after the ${fieldName}.`;
        showManualEntryDateConfirmation({  messageTitle, isClosingDateField, inputValue, handleDataChange });
        return;
      }
    }
  
    // Checking for group validation
    if (event.target.hasAttribute("data-dateValidationgroup")) {
      const groupElements = Array.from(document.querySelectorAll(`[data-dateValidationgroup="${event.target.getAttribute("data-dateValidationgroup")}"]`));
      if (event.target.hasAttribute("data-dateorder")) {
        const currentOrder = parseInt(event.target.dataset.dateorder);
        const previousElement = groupElements?.find((el) => el.dataset.dateorder === (currentOrder - 1)?.toString());
        const nextElement = groupElements?.find((el) => el.dataset.dateorder === (currentOrder + 1)?.toString());
        // console.log("nextElement.value",  nextElement?.value);
        const currentDateTimestamp = parseDateToTimestamp(inputValue);
        let messageTitle = ``;
        if (previousElement && currentDateTimestamp < parseDateToTimestamp(previousElement.value)) {
          const fieldName = getTextBeforeInput(previousElement);
          messageTitle = `The selected date should not be before the ${fieldName}.`;
        } else if (nextElement && currentDateTimestamp > parseDateToTimestamp(nextElement.value)) {
          const nextFieldName = getTextBeforeInput(nextElement);
          messageTitle = `The selected date should not be after the ${nextFieldName}.`;
        } else if (previousElement && nextElement && parseDateToTimestamp(previousElement.value) > currentDateTimestamp && currentDateTimestamp < parseDateToTimestamp(nextElement.value)) {
          const previousFieldName = getTextBeforeInput(previousElement);
          const nextFieldName = getTextBeforeInput(nextElement);
          messageTitle = `The selected date should not be before the ${previousFieldName} ${previousFieldName && nextFieldName ? " and " : ""}after the ${nextFieldName}.`;
        }
  
        if (messageTitle) {
          showManualEntryDateConfirmation({  messageTitle, isClosingDateField, inputValue, handleDataChange });
          return;
        }
      }
    }
    // console.log("everyThing is correct", inputValue);
    let formattedData = {
      type: inputValue ? "date" : "text",
      value: inputValue?inputValue:[...noChangeCheckboxContractTypes, "ET"].includes(rootDocData?.contractType) ? "" :"N/A",
    };
    if (isClosingDateField) formattedData = formattedData.value;
    handleDataChange({ formattedData });
  } catch (error) {
    console.log("error happens in manually Entered date field", error?.message)
  }
}

export function handleConfirmModal({event, specificData, removeSection, handleInputSave, currentUrlId}) {
  if (event.target.checked) {
    let checkReference = false;
    if (event.target.name === event.target.parentNode.parentNode.id) {
      const containerId = document.getElementById(event.target.name).id || event.target.parentNode.parentNode.id;
      checkReference = hasReferencedElement({containerId, currentUrlId});
    }
    confirm({
      title: checkReference ? `This Section also contains the reference from the other parts of the document.` : `Remove Section`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to remove this Section?",
      onOk() {
        event.target.checked = true;
        const dirtyFieldsUpdate = removeSection(event.target);
        for (const key in dirtyFieldsUpdate) {
          specificData[key] = dirtyFieldsUpdate[key];
        }
        specificData[event.target.name] = [event.target.value];
        // console.log("dirtyFieldsUpdate", dirtyFieldsUpdate);
        handleInputSave(specificData);
        let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
        localData = {
          ...localData,
          ...specificData,
        };
        localStorage.setItem(currentUrlId, JSON.stringify(localData));
      },
      onCancel() {
        event.target.checked = false;
      },
    });
  } else {
    const dirtyFieldsUpdate = removeSection(event.target);
    for (const key in dirtyFieldsUpdate) {
      specificData[key] = dirtyFieldsUpdate[key];
    }
    specificData[event.target.name] = "";
    return specificData;
  }
}

function hasReferencedElement({containerId, currentUrlId}) {
  const container = document.getElementById(containerId);
  if (container) {
    const elements = Array.from(container.querySelectorAll("[data-referenced-with-table]"));
    const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    const valueArray = elements
      .map((ele) => {
        const val = ele.dataset.referencedWithTable;
        // check if the respective referenced elements have value or date present in the table
        if (localData[val] && localData[val] !== "" && localData[val] !== 0 && localData[val] !== "0.00") {
          ele.style.backgroundColor = "rgb(64, 255, 53)";
          setTimeout(() => {
            ele.style.backgroundColor = "";
          }, 5000);
          return val;
        } else {
          return null;
        }
      })
      .filter((val) => val !== null);
    return valueArray.length > 0;
  }
  return false;
}

export function checkListingContainer(fieldToBeChecked, type) {
  const listingContainer = document.querySelector(".listingTableContainer");

  if (!listingContainer) {
    return null;
  }

  const inputFields = Array.from(listingContainer.querySelectorAll("input, textarea"));
  const listingCompanyDetailKeysArr = inputFields?.filter((e) => e.type !== "checkbox").map((field) => field.name) || [];

  switch (type) {
    case "checkIsPresent":
      return listingCompanyDetailKeysArr?.includes(fieldToBeChecked);
    case "getFieldNames":
      return listingCompanyDetailKeysArr;
    default:
      return null;
  }
}

export function pasteListingCoDataIntoSellingCo({localData, specificData}) {
  let SellerAgentData = localData['SellerAgents'][0];
  let personId = SellerAgentData?.personId;
  specificData = {
    ...specificData,
    [`${personId}_firmName_buying`]: localData[`${personId}_firmName_selling`] || SellerAgentData?.firmName,
    [`${personId}_firmLicense_buying`]: localData[`${personId}_firmLicense_selling`] || SellerAgentData?.firmLicense,
    [`${personId}_address_buying`]: localData[`${personId}_address_selling`] || SellerAgentData?.address,
    [`${personId}_fullName_buying`]: localData[`${personId}_fullName_selling`] || SellerAgentData?.fullName,
    [`${personId}_license_buying`]: localData[`${personId}_license_selling`] || SellerAgentData?.license,
    [`${personId}_phoneNumber_buying`]: localData[`${personId}_phoneNumber_selling`] || SellerAgentData?.phoneNumber,
    [`${personId}_faxNumber_buying`]: localData[`${personId}_faxNumber_selling`] || SellerAgentData?.faxNumber,
    [`${personId}_email_buying`]: localData[`${personId}_email_selling`] || SellerAgentData?.email,
    [`${personId}_commission_buying`]: localData[`${personId}_commission_selling`] || SellerAgentData?.commission,
    [`${personId}_commissionPaidBy_buying`]: localData[`${personId}_commissionPaidBy_selling`] || SellerAgentData?.commissionPaidBy,
    [`${personId}_cellPhoneNumber_buying`]: localData[`${personId}_cellPhoneNumber_selling`] || SellerAgentData?.cellPhoneNumber,
    [`${personId}_otherRequest_buying`]: localData[`${personId}_otherRequest_selling`] || SellerAgentData?.otherRequest,
  }
  return specificData;
}

export function pasteSellingCoDataIntoListingCo({localData, specificData}) {
  let BuyerAgentData = localData['BuyerAgents'][0];
  let personId = BuyerAgentData?.personId;
  specificData = {
    ...specificData,
    [`${personId}_firmName_selling`]: localData[`${personId}_firmName_buying`] || BuyerAgentData?.firmName,
    [`${personId}_firmLicense_selling`]: localData[`${personId}_firmLicense_buying`] || BuyerAgentData?.firmLicense,
    [`${personId}_address_selling`]: localData[`${personId}_address_buying`] || BuyerAgentData?.address,
    [`${personId}_fullName_selling`]: localData[`${personId}_fullName_buying`] || BuyerAgentData?.fullName,
    [`${personId}_license_selling`]: localData[`${personId}_license_buying`] || BuyerAgentData?.license,
    [`${personId}_phoneNumber_selling`]: localData[`${personId}_phoneNumber_buying`] || BuyerAgentData?.phoneNumber,
    [`${personId}_faxNumber_selling`]: localData[`${personId}_faxNumber_buying`] || BuyerAgentData?.faxNumber,
    [`${personId}_email_selling`]: localData[`${personId}_email_buying`] || BuyerAgentData?.email,
    [`${personId}_commission_selling`]: localData[`${personId}_commission_buying`] || BuyerAgentData?.commission,
    [`${personId}_commissionPaidBy_selling`]: localData[`${personId}_commissionPaidBy_buying`] || BuyerAgentData?.commissionPaidBy,
    [`${personId}_cellPhoneNumber_selling`]: localData[`${personId}_cellPhoneNumber_buying`] || BuyerAgentData?.cellPhoneNumber,
    [`${personId}_otherRequest_selling`]: localData[`${personId}_otherRequest_buying`] || BuyerAgentData?.otherRequest,
  }
  return specificData;
}

export function setSellingSideEmpty({specificData, personId}) {
  specificData = {
    ...specificData,
    [`${personId}_firmName_selling`]: '',
    [`${personId}_firmLicense_selling`]: '',
    [`${personId}_address_selling`]: '',
    [`${personId}_fullName_selling`]: '',
    [`${personId}_license_selling`]: '',
    [`${personId}_phoneNumber_selling`]: '',
    [`${personId}_faxNumber_selling`]: '',
    [`${personId}_email_selling`]: '',
    [`${personId}_commission_selling`]: '',
    [`${personId}_commissionPaidBy_selling`]: '',
    [`${personId}_cellPhoneNumber_selling`]: '',
    [`${personId}_otherRequest_selling`]: '',
  }
  return specificData;
}
export function setBuyingSideEmpty({specificData, personId}) {
  specificData = {
    ...specificData,
    [`${personId}_firmName_buying`]: '',
    [`${personId}_firmLicense_buying`]: '',
    [`${personId}_address_buying`]: '',
    [`${personId}_fullName_buying`]: '',
    [`${personId}_license_buying`]: '',
    [`${personId}_phoneNumber_buying`]: '',
    [`${personId}_faxNumber_buying`]: '',
    [`${personId}_email_buying`]: '',
    [`${personId}_commission_buying`]: '',
    [`${personId}_commissionPaidBy_buying`]: '',
    [`${personId}_cellPhoneNumber_buying`]: '',
    [`${personId}_otherRequest_buying`]: '',
  }
  return specificData;
}

export function updateIndividualChangedField({event, personId, specificData }) {
  let fieldUpdated = event.target.name;
  let value = event.target.value;

  // firmName
  if (fieldUpdated === `${personId}_firmName_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_firmName_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_firmName_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_firmName_buying`]: value,
    }
  }

  // firmLicense
  if (fieldUpdated === `${personId}_firmLicense_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_firmLicense_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_firmLicense_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_firmLicense_buying`]: value,
    }
  }

  // address
  if (fieldUpdated === `${personId}_address_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_address_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_address_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_address_buying`]: value,
    }
  }

  // fullName
  if (fieldUpdated === `${personId}_fullName_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_fullName_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_fullName_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_fullName_buying`]: value,
    }
  }

  // license
  if (fieldUpdated === `${personId}_license_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_license_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_license_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_license_buying`]: value,
    }
  }

  // phoneNumber
  if (fieldUpdated === `${personId}_phoneNumber_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_phoneNumber_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_phoneNumber_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_phoneNumber_buying`]: value,
    }
  }

  // faxNumber
  if (fieldUpdated === `${personId}_faxNumber_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_faxNumber_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_faxNumber_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_faxNumber_buying`]: value,
    }
  }

  // email
  if (fieldUpdated === `${personId}_email_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_email_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_email_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_email_buying`]: value,
    }
  }

  // commission
  if (fieldUpdated === `${personId}_commission_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_commission_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_commission_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_commission_buying`]: value,
    }
  }

  // commissionPaidBy
  if (fieldUpdated === `${personId}_commissionPaidBy_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_commissionPaidBy_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_commissionPaidBy_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_commissionPaidBy_buying`]: value,
    }
  }

  // cellPhoneNumber
  if (fieldUpdated === `${personId}_cellPhoneNumber_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_cellPhoneNumber_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_cellPhoneNumber_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_cellPhoneNumber_buying`]: value,
    }
  }

  // otherRequest
  if (fieldUpdated === `${personId}_otherRequest_buying`) {
    specificData = {
      ...specificData,
      [`${personId}_otherRequest_selling`]: value,
    }
  }
  if (fieldUpdated === `${personId}_otherRequest_selling`) {
    specificData = {
      ...specificData,
      [`${personId}_otherRequest_buying`]: value,
    }
  }

  return specificData;
}

export function checkInCompleteSellers(clientsArray = []) {
  if (!clientsArray || !clientsArray?.length) {
    return true;
  }
  for (let ele of clientsArray) {
    if (ele?.isCorp && (!ele?.signers || !ele.signers?.length)) {
      return true;
    } else if (ele?.isCorp && ele?.signers?.length) {
      for (let el of ele.signers) {
        if (!el.personId) {
          return true;
        }
      }
    } else {
      if (ele?.isCorp === false && !ele?.personId) {
        return true;
      } else if (!ele.isCorp && !ele.personId && ele.key) {
        return true;
      }
    }
  }
  return false;
}

export const showDeleteConfirmToBroker = ({ event, currentUrlId, handleInputSave, rootDocData }) => {
  let localData = {};
  try {
    localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
  } catch (error) {
    return;
  }

  const personId = event.target.getAttribute("data-signer-personId");
  const uuidKey = event.target.getAttribute("data-signer-uuid-key");
  const signBlockIsFor = event.target.getAttribute("data-block-is-for");
  const agentType = signBlockIsFor === "CO_BUYER_BROKER" ? "BuyerAgents" : "SellerAgents";
  //  console.log("signBlockIsFor", personId, uuidKey, signBlockIsFor);
  if (!localData[agentType] || localData[agentType].length === 0) {
    message.error("No brokers available to remove.");
    return;
  }
  let selectedAgentData = null;
  if (personId?.length === 24) {
    selectedAgentData = localData[agentType]?.find((el) => el.personId === personId);
  } else if (uuidKey) {
    selectedAgentData = localData[agentType]?.find((el) => el.key === uuidKey);
  }

  if (localData[agentType].length === 1) {
    message.error("At least one broker is required in this contract.");
    return;
  }

  if (!selectedAgentData) {
    message.error("No matching broker found.");
    return;
  }

  Modal.confirm({
    title: `Are you sure you want to remove ${selectedAgentData?.fullName || "this agent"} from this form?`,
    content:rootDocData?.contractType === "ERTS" ? "Note: This will also update the Section 3 fields automatically." :  "",
    okText: "Yes",
    cancelText: "No",
    icon: <ExclamationCircleOutlined />,
    okType: "danger",
    onOk() {
      // Removing the agent using personId or key (uuidKey)
      if (personId?.length === 24) {
        localData[agentType] = localData[agentType].filter((el) => el.personId !== personId);
      } else if (uuidKey) {
        localData[agentType] = localData[agentType].filter((el) => el.key !== uuidKey);
      }
      let transactionObj = {}
      if (rootDocData?.contractType === "ERTS") {
        updateSection3Fields({localData, agents:localData[agentType], agentType, transactionObj});
      }
      handleInputSave({ [agentType]: localData[agentType], ...transactionObj });
      localStorage.setItem(currentUrlId, JSON.stringify(localData));
      setTimeout(() => {
        message.success("Broker removed successfully.");
      }, 2000);
    },
    onCancel() {},
  });
};


export function checkOnlySignersAllowedToEdit (rootDocData){
  // const { openFor, contractType, RTDInitiator } = rootDocData || {};

  // signatureInfo?.some(el => ["BUYER", "BUYER_AGENT"].includes(el.roleType));
  // if (createOfferContractTypes.includes(contractType) && openFor === "SELLER_AGENT") {
  //   return true;
  // }
  // if (!createOfferContractTypes.includes(contractType)) {
  //   if (RTDInitiator === "BUYER" && openFor === "SELLER_AGENT") {
  //     return true;
  //   } else if (RTDInitiator === "SELLER" && openFor === "BUYER_AGENT") {
  //     return true;
  //   }
  // }
  return false;
}; 

export const combineBrokersFirmName = (agents) => {
  if (!Array.isArray(agents)) return "";
  const firmsMap = new Map();
  agents.forEach((agent) => {
    if (agent && typeof agent === 'object') {
      const { resureFirmId, firmName } = agent;
      const key = resureFirmId ? String(resureFirmId) : firmName?.toLowerCase();
      if (key && !firmsMap.has(key)) {
        firmsMap.set(key, firmName);
      }
    }
  });
  return Array.from(firmsMap.values()).join(", ");
};

export const updateSection3Fields = ({ localData, agents = [], agentType, transactionObj }) => {
  const firmkey = agentType === "BuyerAgents" ? "[data-buyer-brokers-firm-list]" : "[data-seller-brokers-firm-list]";
  const brokerKey = agentType === "BuyerAgents" ? "[data-buyer-brokers-list]" : "[data-seller-brokers-list]";
  const agentStr = getFullNamesFromArray(agents);
  const brokerElements = document.querySelectorAll(brokerKey);
  if (brokerElements?.length) {
    brokerElements.forEach((element) => {
      element.value = agentStr;
      localData[element.name] = agentStr;
      localData[element.name] = agentStr;
      if (transactionObj) {
        transactionObj[element.name] = agentStr;
      }
      element.parentNode.dataset.replicatedValue = agentStr;
    });
  }

  const combinedFirmNames = combineBrokersFirmName(agents);
  const sellerBrokerFirmElements = document.querySelectorAll(firmkey);
  if (sellerBrokerFirmElements?.length) {
    sellerBrokerFirmElements.forEach((element) => {
      element.value = combinedFirmNames;
      localData[element.name] = combinedFirmNames;
      if (transactionObj) {
        transactionObj[element.name] = combinedFirmNames;
      }
      element.parentNode.dataset.replicatedValue = combinedFirmNames;
    });
  }
};

