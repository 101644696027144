/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect, useLocation } from "react-router-dom";
import { contractDataHandle } from "./state/actions";
import { Layout, Row, Modal, message } from "antd";
import "./styles/RenderJsx.css";
import "./styles/FormFromHtml.css";
import { Loading } from "../Common";
import { NotAutharised } from "../Common/components/NotAutharised";
import { ContractNav } from "./components/ContractNav";
import { SomeThingWentWrong } from "../Common/components/SomeThingWentWrong";
import {  ctrlShiftKey, makeAllFieldsDisabled } from "./features/helperFunctions";
import dotEnv from "dotenv";
import OpenCompressed from "./components/OpenCompressed";
import ReactHtmlParser from "react-html-parser";
import { handleClickEvent, handleBlurEvent, handleInputEvent } from "./features/FormLogic/AllForms";
import CobuyerCosellerList from "./components/CobuyerCosellerList";
import SetInitialValues from "./features/SetInitialValues";
import { contractStoreHandle } from "../ContractStore/state/actions";
import AddDocClause from "./components/AddDocClause";
import { PDFview } from "./components/PDFview";
import { closingActions } from "../TransactionReport/state/actions";
import TitleOfficeModal from "./components/TitleOfficeModal";
import { useHistory } from "react-router-dom";
import ModifyDatesModal from "./components/ModifyDatesModal";
import { encryptData } from "../Common/commondata/commonData";
import { filterEmptyAndUndefinedKeys, handleShowPicker } from "../Common/utils/universalFuntions";
import axios from "axios";
import EditAgentsModal from "./components/EditAgentsModal";
import EditPropertyAddressModal from "./components/EditPropertyAddressModal";
dotEnv.config();

const { Content } = Layout;

function CreateOffer() {
  const [state, setState] = useState({ jsx: null, navjsx: null });
  const [emailModalOpen, setEmailModalOpen] = useState({
    isEmailModalVisible: false,
    sendMultipleDoc: true,
  });
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tabId = query.get('tabId');
  const { pathname } = useLocation();
  
  const [modalState, setModalState] = useState({
    openClientListModel: false,
    editAgentsModal: false,
    editPropertyAddressModal: false,
    clientType: "",
    agentType: "",
    event: {},
    showDateFieldModal: false,
    openClauseModal: false,
    personId: "",
    uuidKey:"",
    indidualDetailsEdit: false,
    values: {},
  });

  const history = useHistory();
  
  const { openTitleCompanyModal, titleOfficeLogoModal, transactionEmail, sharedDocs } = useSelector((state) => state.transactionReport);
  const { getTransactionReportData, getBuyersData, getSellersData } = useSelector((state) => state.contractStore);
  const { id: urlId } = useParams();
  window.onbeforeunload = function () {
    localStorage.removeItem(urlId);
  };
  const dispatch = useDispatch();
  const { currentUrlId, contractAuthKey, resetSignatureLoading } = useSelector((state) => state.createOffer);

  useEffect(() => {
    let unmounted = {
      value: false,
    };
    let sendEmailSource={
      source:"client"
    }
    let sourceAxios = axios.CancelToken.source();
    if (urlId) {
      dispatch(contractDataHandle.setUrlIdFun(urlId));
      dispatch(contractDataHandle.getCreateOfferData({ urlId, unmounted, sourceAxios,sendEmailSource, history}));
    }
    
    return () => {
      dispatch(contractDataHandle.resetCompressedViewData());
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
      if (urlId) {
        localStorage.removeItem(urlId);
      }
    };
  }, [urlId]);

  const { rootDocData, contractData } = useSelector((state) => state.createOffer);
  // setting the html forms after fetching data
  useEffect(() => {
    if (contractData?.html) setState((prevState) => ({ ...prevState, jsx: ReactHtmlParser(contractData.html) }));
    if (contractData?.nav) setState((prevState) => ({ ...prevState, navjsx: ReactHtmlParser(contractData.nav) }));
  }, [contractData]);

  useEffect(() => {
    if (state.jsx && rootDocData) {
      if (rootDocData.contractType === "TR" && getTransactionReportData) {
        SetInitialValues({ currentUrlId, rootDocData, contractAuthKey, getTransactionReportData, getBuyersData, getSellersData, transactionEmail, sharedDocs });
      } else {
        SetInitialValues({ currentUrlId, rootDocData, contractData, contractAuthKey });
      }
    }
  }, [state.jsx, rootDocData, getTransactionReportData]);

  const handleInputSave = useCallback(
    (valueObj) => {
      const filteredObj = filterEmptyAndUndefinedKeys(valueObj);
      if (Object.keys(filteredObj)?.length) {
        dispatch(contractStoreHandle.autoSaveContract({currentUrlId, contractAuthKey, valueObj:filteredObj}));
      }
    },
    [currentUrlId, contractAuthKey, dispatch]
  );

  const clickHandler = useCallback(
    (event) => {
      handleClickEvent({ event, currentUrlId, rootDocData, dispatch, handleInputSave });
      let localData = JSON.parse(localStorage.getItem(currentUrlId));
      if (event.target.hasAttribute("data-buyer-edit-icon")) {
        setModalState((prevState) => ({ ...prevState, openClientListModel: true, clientType: "Buyers" }));
      } else if (event.target.hasAttribute("data-seller-edit-icon")) {
        if (rootDocData?.openFor === "BUYER") {
          message.info("Only the Buyer's Agent can enter seller names.", 4);
        } else {
          setModalState((prevState) => ({ ...prevState, openClientListModel: true, clientType: "Sellers" }));
        }
      } else if (event.target.hasAttribute("data-co-buyer-broker-edit-icon") || event.target.hasAttribute("data-co-seller-broker-edit-icon")) {
        const personId = event.target.getAttribute("data-signer-personId");
        const uuidKey = event.target.getAttribute("data-signer-uuid-key");
        const signBlockIsFor = event.target.getAttribute("data-block-is-for");
        setModalState((prevState) => ({
          ...prevState,
          editAgentsModal: true,
          personId: personId?.length === 24 ? personId : null,
          agentType: signBlockIsFor === "CO_BUYER_BROKER" ? "BuyerAgents" : "SellerAgents",
          uuidKey,
          indidualDetailsEdit: true,
        }));
      } else if (event.target.hasAttribute("data-add-coBuyerBroker-btn")) {
        setModalState((prevState) => ({ ...prevState, editAgentsModal: true, agentType: "BuyerAgents" }));
      } else if (event.target.hasAttribute("data-add-coSellerBroker-btn")) {
        setModalState((prevState) => ({ ...prevState, editAgentsModal: true, agentType: "SellerAgents" }));
      } else if (contractData?.propertyAddressEditable && event.target.name === "FullAddress") {
        setModalState((prevState) => ({
          ...prevState,
          editPropertyAddressModal: true,
        }));
      }

      if (event.target.hasAttribute("data-add-DocClause")) {
        setModalState((prevState) => ({
          ...prevState,
          values: { refName: event.target.dataset.addDocclause, value: event.target.innerText },
          openClauseModal: true,
        }));
      }

      if (event.target.hasAttribute("data-add-title-office")) {
        if (localData?.SelectedBy === undefined || localData?.SelectedBy === "" || rootDocData?.openFor === localData?.SelectedBy) {
          dispatch(closingActions.titleCompanyModalState({ openTitleCompanyModal: true }));
          dispatch(closingActions.getTitleCompany(contractAuthKey));
        }
      }

      if (event.target.classList.contains("relativeDateInput") || event.target.classList.contains("relativeTimeInput")) {
        setModalState((prevState) => ({ ...prevState, showDateFieldModal: true, event }));
      }

      if (rootDocData?.contractType === "TR" && event.target.hasAttribute("data-open-email")) {
        document.cookie = `${tabId}_page2=${pathname}; domain=.resure.realestate; path=/;`;
        // Transaction report > open email logic
        const currentObject = JSON.parse(event.target.dataset.openEmail);
        currentObject.contractAuthKey = contractAuthKey;
        let encryptedCurrentObject = encryptData(JSON.stringify(currentObject));
        history.push(`/needToKnowEmails?data=${encodeURIComponent(encryptedCurrentObject)}&tabId=${tabId}`);
      }
    },
    [currentUrlId, rootDocData, dispatch, history]
  );
  
  

  const handleInput = useCallback(
    (event) => {
      handleInputEvent({ event, currentUrlId, handleInputSave, rootDocData });
    },
    [currentUrlId, handleInputSave, rootDocData]
  );

  const handleBlur = useCallback(
    (event) => {
      handleBlurEvent({ event, currentUrlId, handleInputSave });
    },
    [currentUrlId, handleInputSave]
  );

  useEffect(() => {
    if (state.jsx && rootDocData) {
      document.querySelectorAll(".clickable")?.forEach((element) => {
        element.addEventListener("click", clickHandler);
      });

      document.querySelectorAll("input,textarea")?.forEach((element) => {
        if (element.name !== "no-event" && element.disabled === false) {
          if (element.type === "date") {
            element.addEventListener("click", handleShowPicker);
          }

          element.addEventListener("input", handleInput);
          element.addEventListener("blur", handleBlur);
        }
      });

      let formWrapper = document.querySelector(".form-wrapper");
      if (formWrapper) {
        let zoom = 1;
        document.querySelector(".zoom-plus-button_1")?.addEventListener("click", function () {
          formWrapper.style.transform = `scale(${(parseFloat(zoom) + 0.2).toFixed(2)})`;
          formWrapper.style.transformOrigin = "top left";
          zoom = parseFloat(zoom) + 0.2;
        });
        document.querySelector(".zoom-minus-button_1")?.addEventListener("click", function () {
          if (zoom - 1 > 0) {
            formWrapper.style.transform = `scale(${(parseFloat(zoom) - 0.2).toFixed(2)})`;
            zoom = parseFloat(zoom) - 0.2;
          }
        });
      }
    }

    // Cleanup function to remove the event listeners
    return () => {
      document.querySelectorAll(".clickable")?.forEach((element) => {
        element.removeEventListener("click", clickHandler);
      });

      document.querySelectorAll("input,textarea")?.forEach((element) => {
        if (element.type === "date") {
          element.removeEventListener("click", handleShowPicker);
        }

        element.removeEventListener("input", handleInput);
        element.removeEventListener("blur", handleBlur);
      });
    };
  }, [state.jsx, rootDocData]);

  let handleCancel = () => {
    setModalState((prevState) => ({ ...prevState, openClientListModel: false }));
  };

  // disabling the right click
  useEffect(() => {
    if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "qa") {
    } else {
      let root = document.getElementById("root");
      root.setAttribute("oncontextmenu", "return false");
      document.onkeydown = (e) => {
        if (e.keyCode === 123 || ctrlShiftKey(e, "I") || ctrlShiftKey(e, "J") || ctrlShiftKey(e, "C") || (e.ctrlKey && e.keyCode === "U".charCodeAt(0))) return false;
      };
    }
  }, []);

  // All fields disabled logic
  useEffect(() => {
    if (state.jsx && currentUrlId && rootDocData) {
      makeAllFieldsDisabled(currentUrlId, rootDocData, contractData);
    }

    return () => {};
  }, [state.jsx, currentUrlId, rootDocData]);

  const { contractError, loadingSave, error } = useSelector((state) => state.createOffer);

  setTimeout(() => {
    let rpanrResize = document.getElementById("rpanrResize");
    let pan1 = document.getElementById("pan1");
    let ismdwn = 0;
    rpanrResize && rpanrResize.addEventListener("mousedown", mD);

    function mD(event) {
      ismdwn = 1;
      document.body.addEventListener("mousemove", mV);
      document.body.addEventListener("mouseup", end);
    }

    function mV(event) {
      if (ismdwn === 1) {
        pan1.style.flexBasis = event.clientX + "px";
      } else {
        end();
      }
    }
    const end = (e) => {
      ismdwn = 0;
      document.body.removeEventListener("mouseup", end);
      rpanrResize && rpanrResize.removeEventListener("mousemove", mV);
    };
  }, 300);

  const renderPdfView = useMemo(() => <PDFview jsx={state.jsx} navjsx={state.navjsx} />, [state.jsx, state.navjsx]);





  if (!urlId) {
    return <Redirect to="/error" />;
  }
  if (contractError) {
    return (
      <div style={{ height: "100vh" }}>
        <NotAutharised flag={true} />
      </div>
    );
  }
  return (
    <>
      {loadingSave || !contractData || resetSignatureLoading ? (
        <div style={{ height: "100%" }}>
          <Loading />
        </div>
      ) : error ? (
        <SomeThingWentWrong message={error.message} status={error.statusCode} />
      ) : (
        <Layout style={{ background: "#F0F2F5", height: "100%" }}>
          <>
            {contractData && state.jsx && <ContractNav emailModalOpen={emailModalOpen} setEmailModalOpen={setEmailModalOpen}/>}
            <Content style={{ width: "-webkit-fill-available" }}>
              {contractData && state.jsx && state.navjsx ? (
                <Row style={{ rowGap: "1px", position: "absolute", width: "-webkit-fill-available" }}>
                  <div className="reSizerWrapper">
                    <div id="pan1" className="form-from-html no-print">
                      <div style={{ overflowY: "scroll" }} className="no-print">
                        {state.navjsx}
                      </div>
                    </div>
                    <div id="rpanrResize" style={{ display: "flex", alignItems: "center" }} className="no-print">
                      <OpenCompressed />
                    </div>
                    <div id="pan2" className="print htmlElements form-from-html">
                      {renderPdfView}
                    </div>
                  </div>
                </Row>
              ) : (
                <div id="pan2" className="print htmlElements form-from-html withoutNav">
                  {state.jsx && renderPdfView}
                </div>
              )}
            </Content>
          </>
        </Layout>
      )}
      {modalState?.openClientListModel && (
        <Modal visible={true} width={"600px"} title={<span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>{modalState.clientType === "Buyers" ? "EDIT BUYERS" : `EDIT ${rootDocData?.contractType === 'ERTL' ? 'LANDLORDS' : rootDocData?.contractType === 'ETCT' ? 'TENANTS' : "SELLERS"}`}</span>} onCancel={handleCancel} footer={false}>
          <CobuyerCosellerList clientType={modalState.clientType} sourceType={(((modalState.clientType === "Sellers") && (rootDocData?.openFor === "BUYER_AGENT")) || ((modalState.clientType === "Buyers") && (rootDocData?.openFor === "SELLER_AGENT"))) ? "MANUAL_ADD_CLIENTS" : "NORMAL"} contractAuthKey={contractAuthKey} rootDocData={rootDocData} currentUrlId={currentUrlId} />
        </Modal>
      )}
      {modalState?.editAgentsModal && <EditAgentsModal modalState={modalState} setModalState={setModalState} currentUrlId={currentUrlId} handleInputSave={handleInputSave} rootDocData={rootDocData} contractAuthKey={contractAuthKey} contractData={contractData} />}
      {modalState?.openClauseModal && <AddDocClause setOpenClauseModal={(openClauseModal) => setModalState((prevState) => ({ ...prevState, openClauseModal }))} currentUrlId={currentUrlId} values={modalState.values} contractAuthKey={contractAuthKey} handleInputSave={handleInputSave} />}
      {(openTitleCompanyModal || titleOfficeLogoModal) && <TitleOfficeModal />}
      {modalState?.showDateFieldModal && <ModifyDatesModal modalState={modalState} setModalState={setModalState} currentUrlId={currentUrlId} handleInputSave={handleInputSave} rootDocData={rootDocData} />}
      {modalState?.editPropertyAddressModal && <EditPropertyAddressModal modalState={modalState} setModalState={setModalState} currentUrlId={currentUrlId} handleInputSave={handleInputSave} rootDocData={rootDocData}  contractAuthKey={contractAuthKey} contractData={contractData} />}
    </>
  );
}

export { CreateOffer };
